@import "../../../../../vars.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  .text-container {
    @include tablet {
      display: none;
    }
  }

  .text {
    color: var(--header-top-section-font-color);
    font-family: Montserrat;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: var(--base-light);
    font-weight: 700;
  }

  .icon {
    width: 27px;
    height: 32px;
    background: url("../../../../../assets//mayor_projects_icon.svg") 0 0 /
      contain no-repeat;
    fill: var(--header-top-section-font-color);

    @include tablet() {
      width: 30px;
      height: 33px;
    }
  }
}
