@import "../../../../../vars";

.cp-popup {
  width: 100%;
  background: white;
  box-shadow: 0px 2px 10px #0000001a;
  position: absolute;
  top: 52px;
  right: 0px;
  width: 290px;
  padding: 32px;
  border-radius: 4px;
  pointer-events: all;
  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    top: -16px;
    right: 36px;
    filter: drop-shadow(0px -2px 6px #0000001a);
    @include phone() {
      display: none;
    }
  }

  @include phone() {
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 100;
    padding-top: 0;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__header {
    display: none;

    @include phone() {
      display: block;

      .logo {
        margin-top: 8px;
      }
    }
  }

  &__title {
    font: var(--text-body1);
    font-weight: 600;
    color: #282828;

    @include phone() {
      margin-top: 20px;
    }
  }

  &__projects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    overflow-y: auto;
  }

  &__project {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    text-decoration: none !important;
    border: none;
    background: transparent;

    &-name {
      font: var(--text-body2);
      font-weight: 600;
      color: #0c1014;
      margin-left: 12px;
    }

    &__img {
      width: 44px;
      height: 44px;
      padding: 10px;

      &--qrcode {
        box-shadow: 0px 0px 4px #00000014;
        border-radius: 10px;
      }
    }
  }
}
