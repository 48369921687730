@import "../../../vars";
$registration-background: #f6f3f1;
$auth-background: #65aae1;

$navbarHeight: 78px;
$navbarHeightTablet: 76px;
$navbarHeightMobile: 61px;

.about-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about {
  @extend %base-text-pages;
  max-width: 1272px;

  padding: #{$header-desk-height-with-top-section}px $containerXPadding 0;

  @include wide-tablet() {
    padding: #{$header-tab-height-with-top-section}px $containerXPadding 0;
  }

  @include phone() {
    padding: #{$header-mob-height-with-top-section}px $containerXPadding 0;
  }

  i {
    font-style: italic;
  }

  .nowrap {
    white-space: nowrap;
  }

  .auth__loggedIn {
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;

    &-icon {
      margin-right: 14px;
      font-size: 22px;
    }
  }

  &-section {
    padding: 36px 0;

    @include tablet() {
      padding: 24px 0;
    }
  }
  &_page__subtitle {
    font: normal normal bold 48px/48px var(--font-family-primary);
    letter-spacing: -0.4px;
    margin-top: 36px;
    @include tablet() {
      font: normal normal bold 28px/28px var(--font-family-primary);
      letter-spacing: 0.21px;
      margin-top: 24px;
    }
  }
  &__subtitle {
    @extend %section-title;
    padding-bottom: 24px;
  }

  &__press {
    font: normal normal normal 18px/22px var(--font-family-primary);
    letter-spacing: 0.17px;
    position: relative;
    padding: 24px 0;
    background-color: #eefbff;
    @include afterBeforeBackground(#eefbff);
    margin-top: 44px;

    @include tablet() {
      font: normal normal normal 16px/20px var(--font-family-primary);
      letter-spacing: 0.29px;
    }

    @include phone() {
      font: normal normal normal 16px/24px var(--font-family-primary);
      letter-spacing: 0.13px;
      text-align: center;
      padding: 36px 0;
    }

    br {
      display: none;

      @include phone() {
        display: block;
      }
    }
  }

  &__registration {
    position: relative;
    background-color: $registration-background;
    margin-top: 24px;
    padding: 36px 0 48px;

    @include adaptive(1024) {
      padding: 24px 0 31px;
      margin-top: 16px;
    }

    @include phone() {
      padding: 20px 0 30px;
    }

    @include afterBeforeBackground($registration-background);

    &-wrapper {
      display: flex;

      @include phone() {
        flex-direction: column;
      }
    }

    &-loggedIn {
      .cr-button {
        margin-top: 24px;
      }

      > span {
        color: #65aae1;
        margin-top: 47px;

        @include adaptive(1024) {
          margin-top: 24px;
        }
      }

      b {
        color: var(--base-text);
      }
    }

    &-image {
      position: relative;
      padding: 0 15px;
      flex: 5;

      @include adaptive(1024) {
        flex: 1;
      }

      @include phone() {
        display: flex;
        justify-content: center;
        height: 227px;
        flex: auto;
        padding: 0;
      }

      &.empty {
        @include adaptive(1024) {
          display: none;
        }
      }

      img {
        position: absolute;
        bottom: -58px;
        height: 340px;
        max-width: 392px;
        width: 100%;
        left: 0;

        @include adaptive(1024) {
          bottom: -36px;
          height: calc(100% + 36px);
          max-width: 348px;
        }

        @include tablet() {
          position: relative;
          bottom: 0;
          height: 100%;
          width: 100%;
        }
      }
    }

    &-content {
      padding: 0 15px;
      flex: 7;

      @include adaptive(1024) {
        flex: 1;
      }

      @include phone() {
        padding: 0;
      }
      .about__subtitle {
        font: normal normal bold 30px/37px var(--font-family-primary);
        letter-spacing: 0.12px;

        @include tablet() {
          font: normal normal bold 20px/24px var(--font-family-primary);
          letter-spacing: 0.15px;
          padding-bottom: 16px;
        }

        @include phone() {
          font: normal normal bold 24px/29px var(--font-family-primary);
          letter-spacing: -0.29px;
        }
      }
      p {
        &:not(:last-child) {
          margin-bottom: 28px;
        }
      }
    }
  }

  &__download {
    &-info {
      display: flex;
      align-items: center;

      @include phone() {
        flex-direction: column;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      flex: 2;
      padding-right: 15px;

      @include tablet() {
        flex: 1;
      }

      @include phone() {
        padding: 0;
        margin-bottom: 25px;
      }
    }

    &-links {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      flex: 1;

      @include adaptive(1024) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
      }

      @include tablet() {
        flex: unset;
      }

      @include phone() {
        flex-direction: row;
        align-items: baseline;
      }

      a {
        display: flex;
        align-items: baseline;

        // &:first-child {
        //   margin-right: 20px;

        //   @include adaptive(1024) {
        //     margin: 0 0 10px;
        //   }

        //   @include phone() {
        //     margin: 0 10px 0 0;
        //   }
        // }
      }
    }
  }

  &__auth {
    position: relative;
    display: flex;
    align-items: center;
    padding: 41px 0;
    color: white;
    background-color: $auth-background;

    @include adaptive(1024) {
      padding: 29px 0;
    }

    @include tablet() {
      padding: 24px 0;
      align-items: flex-start;
      .cr-button {
        width: 136px;
        span {
          font: normal normal bold 11px/14px PT Sans;
          letter-spacing: 0.15px;
          white-space: break-spaces;
        }
      }
    }
    @include phone() {
      flex-direction: column;
      .cr-button {
        width: 100%;
        span {
          font: normal normal bold 14px/19px PT Sans;
          letter-spacing: 1.35px;
        }
      }
    }
    @include afterBeforeBackground($auth-background);

    &-title {
      display: flex;
      flex: 2;
      font: Bold 30px/36px var(--font-family-primary);
      letter-spacing: -0.24px;

      @include adaptive(1024) {
        font: Bold 22px/27px var(--font-family-primary);
        letter-spacing: 0.15px;
        max-width: 72%;
      }

      @include tablet() {
        font: normal normal bold 20px/24px var(--font-family-primary);
        max-width: unset;
        margin-bottom: 20px;
      }

      @include phone() {
        font: normal normal bold 24px/29px var(--font-family-primary);
        letter-spacing: -0.29px;
      }
    }

    &-link {
      justify-content: flex-end;
      flex: 1;
      line-height: 50px;

      @include adaptive(1024) {
        max-width: 28%;
        line-height: inherit;
      }

      @include phone() {
        max-width: unset;
        width: 100%;
      }

      .cr-button-filled {
        background-color: #ce2124;
      }
    }
  }
}
