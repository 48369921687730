@import "../../../vars";

.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -100px;
  height: fit-content;
  margin: auto;

  &__inner {
    position: relative;

    @include phone() {
      margin-top: -30%;
    }
  }

  &__hint {
    font: normal normal normal 14px/20px var(--font-family-primary);
    letter-spacing: 0.25px;
    color: #65aae1;
    position: absolute;
    top: -20px;
    left: 0;
    width: max-content;
  }

  h1 {
    font: normal normal bold 340px/408px var(--font-family-primary);
    letter-spacing: -5.31px;
    color: #65aae1;
    margin: 0;

    @include tablet() {
      font: normal normal bold 300px/360px var(--font-family-primary);
      letter-spacing: -4.69px;
    }

    @include phone() {
      font: normal normal bold 116px/139px var(--font-family-primary);
      letter-spacing: -1.81px;
    }
  }

  &__redirect {
    position: absolute;
    bottom: -20px;
    font: normal normal normal 16px/24px var(--font-family-primary);
    letter-spacing: 0.15px;
    width: max-content;

    > br {
      display: none;
    }

    @include tablet() {
      bottom: -35px;
    }

    @include phone() {
      > br {
        display: block;
      }
    }
  }

  &__decoration {
    position: absolute;
    color: #dedede;
  }

  .decoration-type-1 {
    font: normal normal bold 69px/83px Bebas Neue;
    letter-spacing: -1.08px;
    transform: rotate(-45deg);
    top: 59px;
    left: -79px;

    @include phone() {
      font: normal normal bold 40px/48px Bebas Neue;
      letter-spacing: -0.63px;
      top: 85px;
      left: -45px;
    }
  }

  .decoration-type-2 {
    font: normal normal bold 102px/123px Bebas Neue;
    letter-spacing: -1.59px;
    top: 80px;
    right: -90px;
    transform: rotate(35deg);

    @include phone() {
      font: normal normal bold 60px/72px Bebas Neue;
      letter-spacing: -0.94px;
      top: 56px;
      right: -59px;
    }
  }

  .decoration-type-3 {
    font: normal normal bold 109px/131px Bebas Neue;
    letter-spacing: -1.7px;
    transform: rotate(60deg);
    bottom: -17px;
    left: -100px;

    @include tablet() {
      bottom: -35px;
    }

    @include phone() {
      font: normal normal bold 70px/84px Bebas Neue;
      letter-spacing: -1.09px;
      left: -50px;
      bottom: -115px;
    }
  }

  .decoration-type-4 {
    font: normal normal bold 60px/72px Bebas Neue;
    letter-spacing: -0.94px;
    transform: rotate(120deg);
    right: -90px;
    bottom: 72px;

    @include tablet() {
      bottom: 30px;
    }

    @include phone() {
      font: normal normal bold 35px/43px Bebas Neue;
      letter-spacing: -0.55px;
      bottom: -90px;
      right: -45px;
    }
  }
}
