@import "../../../../vars";

.cross-promo {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;

  &--active {
    pointer-events: none;
  }

  &__button {
    border: none;
    background: transparent;
    width: 30px;
    height: 30px;
    object-fit: cover;
    position: relative;
    top: 2px;

    svg {
      &:hover {
        path {
          fill: #3c8de2;
        }
      }
    }

    @include tablet() {
      width: auto;
      height: auto;
    }

    @include phone() {
      margin-right: 6px;
    }
  }

  &__divider {
    height: 100%;
    width: 1px;
    background-color: #efefef;
    margin-left: 14px;
    margin-right: 14px;

    @include tablet() {
      margin-left: 14px;
      margin-right: 10px;
    }

    @include phone() {
      display: none;
    }
  }

  &__marker {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    right: -1px;
    background-color: #ee4d4d;
    border-radius: 100%;
    border: 1px solid white;
  }
}
