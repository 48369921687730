@import "../../../vars";

.main {
  &.new-line-top {
    padding-top: 50px;
  }

  .sections {
    display: flex;
    flex-direction: column;
    gap: 52px;

    @include tablet {
      gap: 40px;
    }

    @include phone {
      gap: 32px;
    }

    .section {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @include tablet {
        gap: 16px;
      }

      .section-title {
        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            color: inherit;
          }
        }
      }
    }
  }

  :global(.cr-button) {
    letter-spacing: 0;
    &:global(.cr-button-filled) {
      border: none;
      background-color: var(--base-blue);
      &:focus,
      &:active {
        background-color: #1473e6;
      }
      &:hover {
        background-color: #3c8de2;
      }
    }
    &:global(.cr-button-outlined) {
      border-color: var(--base-blue);
      color: var(--base-blue);
      &:active {
        background-color: var(--base-blue);
      }
    }

    padding: 18px 36px;
    border-radius: var(--corners-s);
    font: var(--text-body1);
    font-weight: 600;
    text-transform: uppercase;

    @include tablet {
      padding: 11px 16px 10px;
      border-radius: var(--corners-xxs);
      font: var(--text-body4);
      font-weight: 600;
    }

    @include phone {
      padding: 12px 24px 11px;
      font: var(--text-body3);
      font-weight: 600;
    }

    &:global(.cr-button_mid) {
      padding: 11px 24px 12px;
      border-radius: var(--corners-xxs);
      font: var(--text-body3);
      font-weight: 600;

      @include tablet {
        padding: 8px 16px 9px;
        border-radius: var(--corners-xxs);
        font: var(--text-body4);
        font-weight: 600;
      }

      @include phone {
        padding: 10px 24px 9px;
        font: var(--text-body3);
        font-weight: 600;
      }
    }
  }
}

.ag-video {
  position: absolute;
  z-index: 99;
}
