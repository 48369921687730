@import "../../../vars";

.went-wrong {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
  padding: 0 20px;

  > h1 {
    color: #65aae1;
    font: normal normal bold 60px/72px var(--font-family-primary);
    letter-spacing: -0.5px;
    margin: 74px 0 29px 0;
    @include tablet() {
      font: normal normal bold 50px/60px var(--font-family-primary);
      letter-spacing: -0.42px;
      margin: 75px 0 20px 0;
    }
    @include phone() {
      font: normal normal bold 40px/40px var(--font-family-primary);
      letter-spacing: -0.33px;
      margin: 35px 0 16px 0;
    }
  }

  img {
    width: 270px;
    height: 270px;
    @include phone() {
      width: 144px;
      height: 144px;
    }
  }

  p {
    color: #282828;
    font: normal normal normal 20px/28px var(--font-family-primary);
    letter-spacing: 0.16px;
    text-align: center;
    @include tablet() {
      font: normal normal normal 18px/24px var(--font-family-primary);
      letter-spacing: 0.15px;
    }
    @include phone() {
      font: normal normal normal 16px/24px var(--font-family-primary);
      letter-spacing: 0.13px;
    }
  }

  .cr-button {
    margin-top: 24px;
  }
}
