@import "../../../vars";

@mixin navbar-font {
  text-align: center;
  text-decoration: none;
  position: relative;
  font: var(--text-body2);
  font-weight: 500;
  color: var(--base-text) !important;
  cursor: pointer;

  &:hover {
    color: var(--base-blue);
  }
}

.cr-header {
  $root: &;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  background: white;
  border-bottom: 1px solid #dedede;
  top: 0;
  left: 0;
  right: 0;

  @include adaptive(1024) {
    border-bottom: none;
  }

  @include phone() {
    .profile-pic {
      width: 36px;
    }
  }

  &__loggedIn-name {
    @include navbar-font;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    padding-right: 6px;
    transition: 0.15s ease-in-out;

    .ic {
      display: inline-block;
      transform: rotate(90deg);
      font-size: 14px;
      margin-left: 10px;
      transition: 0.1s ease-in-out;
    }

    &--active {
      color: var(--base-blue) !important;
      .ic {
        transform: rotate(-90deg);
      }
    }
  }

  &__loggedIn-container {
    position: relative;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1272px;
    width: 100%;
    padding: 0 24px;
    min-height: #{$header-tab-height}px;

    @include tablet() {
      padding-right: 14px;
    }

    @include phone() {
      padding-left: 20px;
      padding-right: 10px;
      min-height: #{$header-mob-height}px;
    }

    .logo {
      align-self: flex-start;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-left-column,
    &-right-column {
      display: flex;
      align-items: center;
    }

    &-links {
      display: flex;
      justify-content: flex-start;
      margin-left: 14px;
      width: 100%;

      @include wide-tablet() {
        display: none;
      }

      &-link {
        list-style-type: none;
        padding: 28px 17px;
        position: relative;

        @include adaptive(1224) {
          padding: 28px 8px;
        }

        @include adaptive(900) {
          padding: 23px 4px;
        }

        > * {
          transition: 0.15s ease-in-out;
          &:hover {
            text-decoration: none;
            color: var(--base-blue) !important;
          }

          @include navbar-font;

          @include adaptive(1224) {
            font-size: 14px;
          }

          @include adaptive(1100) {
            font-size: 12px;
          }
        }

        &-badge {
          position: absolute;
          font: Normal 12px/16px Roboto;
          color: white;
          background: var(--base-blue);
          border-radius: 12px;
          padding: 0 8px;
          top: 20px;
          right: -45px;

          @include adaptive(1024) {
            font-size: 10px;
          }
        }
      }

      &-dropdown {
        padding: 28px 17px;
        transition: 0.15s ease-in-out;
        &:hover {
          color: var(--base-blue) !important;
        }

        @include navbar-font;

        @include adaptive(1224) {
          font-size: 14px;
          padding: 28px 8px;
        }

        @include adaptive(1100) {
          font-size: 12px;
        }

        @include adaptive(900) {
          padding: 23px 4px;
        }

        &-menu {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 68px;
          left: -31px;
          padding: 24px;
          background-color: #ffffff;
          box-shadow: 0px 3px 15px rgba(40, 40, 40, 0.149);
          align-items: baseline;
          padding: 24px;
          width: max-content;
          z-index: 10;
          border-radius: var(--round-corners);
          transition: 0.1s ease-in-out;
          transform: scale(0);
          opacity: 0;

          &--active {
            opacity: 1;
            transform: scale(1);
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-bottom-color: #ffffff;
            border-top: 0;
            margin-left: -8px;
            margin-top: -8px;
          }

          &--loggedIn {
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
          }

          > * {
            color: var(--base-text) !important;
            @include navbar-font;

            &:not(:first-child) {
              margin-top: 16px;
            }

            &:hover {
              color: var(--base-blue) !important;
              text-decoration: none;
            }
          }
        }
      }
    }

    &-mobile {
      display: none;
      position: relative;
      justify-content: flex-end;

      @include wide-tablet() {
        display: flex;
      }

      > .ic {
        outline: none;
        cursor: pointer;

        @include wide-tablet() {
          margin: 10px;
        }
      }

      &-dropdown {
        position: absolute;
        background-color: white;
        width: 1000%;
        max-width: 320px;
        top: 35px;
        padding: 15px;
        z-index: 1;
        top: -5px;
        right: -15px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.058);
        border-radius: var(--round-corners);

        @include phone() {
          position: fixed;
          width: 100vw;
          height: 100vh;
          max-width: none;
          left: 0;
          top: 0;
          padding: 20px 30px;
          border-radius: 0;
        }

        &-head {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 15px;

          .cr-header__userlink {
            margin-top: -5px;
          }

          > .ic {
            cursor: pointer;
          }
        }

        &-link {
          position: relative;
          width: fit-content;
          list-style-type: none;
          padding: 10px 0;
          display: flex;
          align-items: center;
          color: #8a8a8a;
          font: var(--text-body1);
          font-weight: bold;
          text-decoration: none;
          letter-spacing: 0.15px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > div {
            > a {
              padding-left: 11px;
              margin-top: 10px;
              display: block;
              color: var(--base-text);
            }
          }

          > a {
            color: var(--base-text);
          }

          &-badge {
            position: absolute;
            font: Normal 12px/16px Roboto;
            color: white;
            background: var(--base-blue);
            border-radius: 12px;
            padding: 0 8px;
            top: 3px;
            right: -60px;
            text-transform: initial;
          }
        }
      }
    }

    &-profile {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &__loggedIn {
        display: flex;
        align-items: center;
        justify-content: center;

        > .ic {
          color: #777;
          font-size: 20px;
          display: none;
          outline: none;
          cursor: pointer;

          @include wide-tablet() {
            display: block;
            font-size: 24px;
            margin: 10px;
          }
        }

        &-user {
          @include wide-tablet() {
            margin: 10px;
          }
        }
      }

      &__loggedOut {
        > .ic {
          color: #282828;
          font-size: 20px;
          outline: none;
          cursor: pointer;
          margin: 10px 10px 10px 2px;
          padding-top: 2px;

          @include tablet() {
            margin-left: 20px;
            margin-right: 15px;
            font-size: 18px;
          }

          @include phone() {
            margin-right: 12px;
          }
        }
      }

      .cr-button {
        @include adaptive(1024) {
          font-size: 14px;
          padding: 4px 8px;
        }

        @include medium-tablet() {
          display: none;
        }
      }
    }

    &-logInOutBtn {
      @include navbar-font;
    }
  }

  &__exit-mob-link {
    position: relative;
    width: fit-content;
    list-style-type: none;
    padding: 10px 0;
    display: flex;
    align-items: center;
    font: Bold 20px/24px Bebas Neue;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    color: var(--base-text);

    &:hover {
      text-decoration: underline;
    }
  }

  &__userlink {
    position: relative;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    background-color: transparent;
    display: block;
    box-shadow: 0 0 0 1px #f5f5f5;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/user/undefined.png");
    margin: 10px 16px 10px 10px;
    cursor: pointer;

    @include adaptive(1024) {
      width: 36px;
      height: 36px;
    }

    &-notifications {
      min-width: 18px;
      height: 22px;
      background: #ff9425 0% 0% no-repeat padding-box;
      border-radius: 12px;
      text-align: center;
      font: var(--text-body3);
      font-weight: 600;
      font-family: var(--font-family-secondary);
      letter-spacing: 0.4px;
      color: #ffffff;
      position: absolute;
      top: -6px;
      right: -12px;
      padding: 2px 7px;
    }
  }

  &__home {
    display: none;

    .cross-promo__divider {
      height: initial;
      margin-right: 16px;
    }
    a {
      text-decoration: none;
    }
    i {
      font-size: 20px;
    }
  }
}
