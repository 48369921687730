@import "../../../../vars";

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.wrapper {
  height: 56px;
  width: 100%;
  background: linear-gradient(
      90deg,
      #0f123100 18.3%,
      #0f1231 24.84% 78.29%,
      #0000 99.49%
    ),
    linear-gradient(185deg, #00173e 18.83%, #0d0847 64.64%, #2e0e8d 80.74%),
    #19172f;

  transition: all 0.3s ease-in-out;
}

.wrapper-hidden {
  height: 0;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  padding: 8px 40px;
  display: flex;
  gap: 110px;
  overflow: hidden;
  --header-top-section-font-color: #ffffff;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @include adaptive(1280) {
    gap: 52px;
  }

  @include wide-tablet() {
    padding: 8px 24px;
  }

  @include tablet() {
    gap: 18px;
  }

  @include phone() {
    padding: 8px 20px;
    padding-right: 36px;
  }

  .link-text {
    color: var(--header-top-section-font-color);
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 24px;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .mayor {
    flex-shrink: 0;
  }
}

.links-container {
  height: 100%;
  width: 100%;
  max-width: 1347px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: slideDown 0.5s ease-out;

  &::-webkit-scrollbar {
    display: none;
  }

  @include phone() {
    gap: 16px;
  }
}

.links {
  width: 100%;
  text-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  gap: 160px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include adaptive(1600) {
    gap: 110px;
  }

  @include adaptive(1440) {
    gap: 80px;
  }

  @include wide-tablet() {
    gap: 32px;
  }

  @include tablet() {
    gap: 16px;
  }

  @include phone() {
    width: 504px;
  }
}
