@import "../../../../vars";

$navbarHeight: 78px;
$navbarHeightTablet: 76px;
$navbarHeightMobile: 61px;
$footerHeight: 300px;

@mixin lessen {
  max-width: $containerMaxWidth;
  padding: #{$header-desk-height-with-top-section}px $containerXPadding 80px;

  @include wide-tablet() {
    padding: #{$header-tab-height-with-top-section}px $containerXPadding 40px;
  }

  @include phone() {
    padding: #{$header-mob-height-with-top-section}px $containerXPadding 32px;
  }
}

.layout {
  &__disable-scroll {
    overflow: hidden;
  }
}

.wrapper {
  min-height: calc(100vh - #{$footerHeight});
  display: flex;
  flex-direction: column;
  max-width: $containerMaxWidth;
  margin: 0 auto;

  @include wide-tablet() {
    padding: #{$navbarHeightTablet} $containerXPadding 0;
  }

  @include phone() {
    padding: #{$navbarHeightMobile} 20px 0;
  }

  &__widen {
    max-width: none;
    padding: #{$header-desk-height-with-top-section}px 0 0;

    @include wide-tablet() {
      padding: #{$header-desk-height-with-top-section}px 0 0;
    }
    @include phone() {
      padding: #{$header-desk-height-with-top-section}px 0 0;
    }
  }

  &__lessen {
    @include lessen;
  }

  &__lessen-main {
    @include lessen;

    @include phone() {
      padding: #{$header-mob-height-with-top-section}px
        #{$containerXPadding-phone} 32px;
    }
  }

  &__fullscreen {
    max-width: unset;
    padding: 0;
    margin: 0;
  }

  main {
    display: flex;
    width: 100%;
    min-height: calc(100% - (#{$navbarHeight} + #{$footerHeight}));
    height: 100%;

    & > div {
      width: 100%;
    }
  }

  &__adaptive {
    min-height: calc(100vh - 300px);
  }
}
